import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import {
  withPrismicUnpublishedPreview,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews';

import {
  getActiveDocLanguage,
  prismicPreviewPages,
} from '../assets/js/utilities';
import linkResolver from '../utilities/link-resolver';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Image from '../components/Image';
import Heading from '../components/Heading';

const NotFoundPage = ({ data }) => {
  // Get global data
  const layout = data.prismicLayout;

  // Get page data
  const notFoundPageData = data.prismic404;
  const {
    message_title,
    message_subtitle,
    link_text,
    image,
  } = notFoundPageData.data;

  // Get translated data
  const activeDoc = getActiveDocLanguage(notFoundPageData);

  return (
    <Layout activeDoc={activeDoc} layoutData={layout.data} variant="fullscreen">
      <Seo title="404" description="Page not found" lang={activeDoc.locale} />

      <Hero>
        <Image image={image} />
        <div className="text-wrapper">
          <p>{message_subtitle}</p>
          <Heading level="h1" size="l" content={message_title} />
          <Link to="/">{link_text}</Link>
        </div>
      </Hero>
    </Layout>
  );
};

export default withPrismicUnpublishedPreview(NotFoundPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO,
    linkResolver: doc => linkResolver(doc),
    componentResolver: componentResolverFromMap(prismicPreviewPages),
  },
]);

/**
 * Styled Components
 *
 */

const Hero = styled.section`
  position: relative;
  width: 100vw;
  height: 95vh;
  min-height: 60rem;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  display: grid;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &:after {
    content: '';
    background-image: linear-gradient(
      to bottom,
      hsl(0, 0%, 0%) 0%,
      hsla(0, 0%, 0%, 0.94) 10.8%,
      hsla(0, 0%, 0%, 0.878) 19.9%,
      hsla(0, 0%, 0%, 0.812) 27.5%,
      hsla(0, 0%, 0%, 0.745) 33.9%,
      hsla(0, 0%, 0%, 0.676) 39.3%,
      hsla(0, 0%, 0%, 0.606) 44%,
      hsla(0, 0%, 0%, 0.535) 48.3%,
      hsla(0, 0%, 0%, 0.465) 52.5%,
      hsla(0, 0%, 0%, 0.394) 56.7%,
      hsla(0, 0%, 0%, 0.324) 61.4%,
      hsla(0, 0%, 0%, 0.255) 66.7%,
      hsla(0, 0%, 0%, 0.188) 73%,
      hsla(0, 0%, 0%, 0.122) 80.4%,
      hsla(0, 0%, 0%, 0.06) 89.4%,
      hsla(0, 0%, 0%, 0) 100%
    );
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    opacity: 0.6;
    margin-left: -50vw;
    margin-right: -50vw;
    width: 100vw;
    height: 12rem;
    z-index: 1;
  }

  .text-wrapper,
  .image-wrapper {
    grid-area: 1 / 1;
  }

  .image-wrapper,
  .image {
    height: 100%;
  }

  .text-wrapper {
    text-align: center;
    color: var(--color-white);
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2.4rem;
    max-width: 80rem;
    width: 100%;

    h1 {
      margin: var(--space-xl) 0 4.8rem;
    }

    a {
      color: var(--color-white);

      &:hover {
        color: var(--color-neon-green);
      }
    }
  }
`;

/**
 * GraphQL
 *
 */
export const query = graphql`
  query($language: String) {
    prismic404(lang: { eq: $language }) {
      uid
      data {
        title
        message_title
        message_subtitle
        link_text
        image {
          gatsbyImageData(width: 1920)
        }
      }
      type
      url
      lang
      alternate_languages {
        lang
        type
        uid
      }
    }
    prismicLayout(lang: { eq: $language }) {
      ...SiteMeta
      ...Menus
      ...ParkInfo
    }
  }
`;
